import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import './searchInput.scss';
const match = require('autosuggest-highlight/match');
const parse = require('autosuggest-highlight/parse');

export default (props) => {
    const { whiskys, placeholder, onSelectWhisky } = {
        ...props,
        whiskys: props.whiskys ? props.whiskys : [],
        placeholder: props.placeholder ? props.placeholder : '',
    };

    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState('');

    const escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const getSuggestions = (value) => {
        const escapedValue = escapeRegexCharacters(value.trim());
        if (escapedValue === '') {
            return [];
        }
        const regex = new RegExp('\\b' + escapedValue, 'i');
        return whiskys.filter(whisky => regex.test(getSuggestionValue(whisky))).slice(0, 5);
    };

    const getSuggestionValue = (suggestion) => {
        return `${suggestion.name}`;
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const renderSuggestion = (suggestion, { query }) => {
        const suggestionText = `${suggestion.name}`;
        const matches = match(suggestionText, query);
        const parts = parse(suggestionText, matches);

        return (
            <span className="suggestion-content">
                {/*<img src={`/static/whiskys/${suggestion.imageNumber.toString().padStart(4, '0')}_WL.jpg`} alt=""/>*/}
                <span className="name">
            {
                parts.map((part, index) => {
                    const className = part.highlight ? 'highlight' : null;

                    return (
                        <span className={className} key={index}>{part.text}</span>
                    );
                })
            }
          </span>
        </span>
        );
    };

    const onChange = (event, { newValue }) => {
        setValue(newValue);

        const selectedWhisky = whiskys.find(whisky => `${whisky.name}` === newValue);
        if (selectedWhisky) {
            onSelectWhisky(selectedWhisky);
        }
    };

    const onShowMe = () => {
        const selectedWhisky = whiskys.find(whisky => `${whisky.name.trim().toLowerCase()}` === value.trim().toLowerCase());
        onSelectWhisky(selectedWhisky || { name: value }, selectedWhisky === undefined);
    };

    return (
        <>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                    value,
                    placeholder,
                    onChange
                }} />


            <button
                id="show-me"
                className={`button is-large is-outlined ${value.length < 3 && 'hidden'}`}
                onClick={onShowMe}
            >Show me</button>
        </>
    );
}
