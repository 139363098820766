import React, {useEffect, useState} from 'react';
import './tasteForm.scss';
import { WithContext as ReactTags } from 'react-tag-input';

export default ({selectedWhisky, title, flavours}) => {
    const [tags, setTags] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [sweet, setSweet] = useState(1);
    const [smokey, setSmokey] = useState(1);
    const [rich, setRich] = useState(1);
    const [sendingForm, setSendingForm] = useState(false);
    const [formHasSent, setFormHasSent] = useState(false);
    const [formResetting, setFormResetting] = useState(false);

    useEffect(() => {
        setSuggestions(flavours);
    }, [flavours]);

    useEffect(() => {
        if (formHasSent) {
            setSweet(1);
            setSmokey(1);
            setRich(1);
            setTags([]);
            setFormResetting(true);
            setTimeout(() => {
                setSendingForm(false);
                setFormHasSent(false);
                setFormResetting(false);
            }, 1000);
        }
    }, [selectedWhisky]);


    const onSubmit = (event) => {
        event.preventDefault();

        if (sendingForm) {
            return;
        }

        setSweet(scale1to10(sweet));
        setSmokey(scale1to10(smokey));
        setRich(scale1to10(rich));

        let formData = {
            'form-name': `${selectedWhisky._id ? 'tasteForm' : 'notFoundForm'}`,
            sweet,
            smokey,
            rich,
            whisky: `${selectedWhisky.name}${selectedWhisky._id ? ` (_id: ${selectedWhisky._id})` : ''}`
        };
        const flavours = tags.reduce((allFlavour, flavour) => {
            allFlavour.push(`${flavour.id}${flavour._id ? ` (_id: ${flavour._id})` : ''}`);
            return allFlavour;
        }, []).join(' | ');

        if (flavours.length) {
            formData['flavours'] = flavours;
        }

        setSendingForm(true);

        fetch('/api/save-taste', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        })
            .then(() => {
                setSendingForm(false);
                setFormHasSent(true);
            })
            .catch(error => alert(error));
    };

    const onDeleteTag = (indexToDelete) => {
        setTags(tags.filter((tag, index) => index !== indexToDelete));
    };

    const onAddTag = (event) => {
        setTags([...tags, event]);
    };

    const KeyCodes = {
        comma: 188,
        enter: 13,
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const scale1to10 = (value) => value > 10 ? 10 : value < 1 ? 1 : value;

    return (
        <div className={`tasteForm ${formHasSent ? 'formHasSent' : ''} ${formResetting ? 'formResetting' : ''}`}>
            <h4 className="title is-4">{title}</h4>

            <form className="card" onSubmit={onSubmit} data-netlify="true" name={`${selectedWhisky._id ? 'tasteForm' : 'notFoundForm'}`}>
                {!selectedWhisky._id && (
                    <p className="whisky-name title is-4">{selectedWhisky.name}</p>
                )}

                <h5 className="title is-5">Scale: 1-10</h5>

                <div className="featuredAttributes">
                    <div>
                        <label>Sweet</label>
                        <input type="number" value={sweet} min="1" max="10" name="sweet" onBlur={e => setSweet(scale1to10(e.target.value))} onChange={e => setSweet(e.target.value)} />
                    </div>
                    <div>
                        <label>Smoky</label>
                        <input type="number" value={smokey} min="1" max="10" name="smokey" onBlur={e => setSmokey(scale1to10(e.target.value))} onChange={e => setSmokey(e.target.value)} />
                    </div>
                    <div>
                        <label>Rich</label>
                        <input type="number" value={rich} min="1" max="10" name="rich" onBlur={e => setRich(scale1to10(e.target.value))} onChange={e => setRich(e.target.value)} />
                    </div>
                </div>

                <input type="hidden" name="whisky" value="whisky" />
                <input type="hidden" name="flavours" value="flavours" />

                <h6 className="title is-6">What do you taste:</h6>

                <ReactTags
                    tags={tags}
                    suggestions={suggestions}
                    delimiters={delimiters}
                    handleDelete={onDeleteTag}
                    handleAddition={onAddTag}
                    allowDragDrop={false}
                    placeholder="Type in flavour..."
                />

                <p>
                    <button
                        className={`button is-outlined ${sendingForm ? 'is-loading' : ''}`}
                        type="submit"
                    >Send</button>
                </p>

                <input type="hidden" name="form-name" value={`${selectedWhisky._id ? 'tasteForm' : 'notFoundForm'}`} />
            </form>

            <h3 className="title is-3 submissionThankYou">Thanks for your submission!</h3>

        </div>
    );
}
