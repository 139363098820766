import React from 'react';

export default ({ email, withLink = true }) => {
    const cryptedMailto = (email) => {
        let n = 0;
        let encryptedHref = "";
        let originalHref = "mailto:" + email;

        for (let i = 0; i < originalHref.length; i++) {
            n = originalHref.charCodeAt(i);
            if (n >= 8364) {
                n = 128;
            }
            encryptedHref += String.fromCharCode(n + 1);
        }
        return encryptedHref;
    };

    const cryptedEmail = (email) => {
        let linkText = email;
        linkText = linkText.replace(/@/, " (at) ");
        return linkText.replace(/\./g, " [dot] ");
    };

    const linkToCryptedMailto = (event) => {
        event.preventDefault();
        window.location.href = unCryptedMailto(event.target.dataset.href);
    };

    const unCryptedMailto = (mailto) => {
        let n = 0;
        let uncrypted = "";
        for (let i = 0; i < mailto.length; i++) {
            n = mailto.charCodeAt(i);
            if (n >= 8364) {
                n = 128;
            }
            uncrypted += String.fromCharCode(n - 1);
        }
        return uncrypted;
    };

    return !withLink
        ? cryptedEmail(email)
        : <a data-href={cryptedMailto(email)} href="#" onClick={linkToCryptedMailto}>{cryptedEmail(email)}</a>;
}
