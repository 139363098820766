import React, { useEffect, useState } from 'react';
import SearchInput from '../../components/searchInput';
import WhiskyCard from '../../components/whisky';
import SimilarWhiskys from '../../components/similarWhiskys';
import TasteForm from '../../components/tasteForm';
import Footer from '../../components/footer';
import EarlyAccessForm from '../../components/earlyAccessForm';

import { IconContext } from "react-icons";
import { FaGlassWhiskey, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import './index.scss';
import { Helmet } from "react-helmet";

export default () => {
  const contactEmailAddress = 'hello@whiskylike.com';

  const [status, setStatus] = useState('loading');
  const [whiskys, setWhiskys] = useState(null);
  const [notFound, setNotFound] = useState(true);
  const [selectedWhisky, setSelectedWhisky] = useState(null);
  const [comparisonCoordinates, setComparisonCoordinates] = useState([]);
  
  useEffect(() => {
    let canceled = false;
    if (status !== 'loading') {
      return;
    }

    fetch('/api/get-all-whiskys').then(async result => {
      if (canceled) {
        return;
      }

      if (result.status !== 200) {
        console.error('Error during loading whiskys :(');
        console.error(result);
        return;
      }

      const data = await result.json();
      const { whiskys, comparisonCoordinates } = data;
      setWhiskys(whiskys);
      setComparisonCoordinates(comparisonCoordinates);
      setStatus('loaded');

      return () => {
        canceled = true;
      };
    });
  }, [status]);

  const onSelectWhisky = async (whisky, notFound = false) => {
    setSelectedWhisky(whisky);
    setNotFound(notFound);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>whisky Like App</title>
        <meta property="og:title" content="whisky Like App" />
        <meta property="og:description" content="Want to explore the wonderful world of whisky, but don't know where to start? Try our free app that will suggest whiskies similar to your favourites!" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:url" content="https://whiskylike.com" />
        <meta property="og:image" content="https://whiskylike.com/static/whiskys/whisky_Like_preview.png" />
      </Helmet>

      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://whiskylike.com">
            whisky Like
          </a>
        </div>

        <div className="menu">
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="social">
                <a className="" href="https://facebook.com/whiskylikeapp">
                  <IconContext.Provider value={{ color: "#fff" }}><FaFacebook /></IconContext.Provider>
                </a>
                <a className="" href="https://twitter.com/whiskylikeapp">
                  <IconContext.Provider value={{ color: "#fff" }}><FaTwitter /></IconContext.Provider>
                </a>
                <a className="" href="https://instagram.com/whiskylikeapp">
                  <IconContext.Provider value={{ color: "#fff" }}><FaInstagram /></IconContext.Provider>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <section className="section">
        <main className="container">
          <div className="columns is-centered">

            <div className="column is-8">
    
              <h1 className="title is-1 has-text-centered">
                <IconContext.Provider value={{ color: "#8f4d02" }}><FaGlassWhiskey /></IconContext.Provider>
                <br/>
                I want a whisky Like
              </h1>

            </div>

          </div>

          <div className="columns is-centered">

            {whiskys ? (
                <div className="column is-8 has-text-centered">
                  <SearchInput
                      placeholder="Type in a whisky's name..."
                      whiskys={whiskys}
                      onSelectWhisky={onSelectWhisky}
                  />
                </div>
            ) : (
                <div className="column is-8 has-text-centered">
                  <p>Loading whiskies...</p>
                  <progress className="progress is-large is-warning" max="100">60%</progress>
                </div>
            )}

          </div>

          <div className="columns is-centered">

            {selectedWhisky && selectedWhisky._id && (
                <>
                  <div className="column has-text-centered">
                    <h4 className="title is-4">Selected whisky:</h4>
                    <WhiskyCard whisky={selectedWhisky} />
                  </div>

                  <div className="column has-text-centered">
                    <TasteForm
                        selectedWhisky={selectedWhisky}
                        title="Tell us your opinion:"
                        flavours={comparisonCoordinates.filter(coordinate => !coordinate.isNotFlavour)}
                    />
                  </div>
                </>
            )}

            {selectedWhisky && !selectedWhisky._id && notFound && (
                <div className="column is-8 has-text-centered">
                  <TasteForm
                      selectedWhisky={selectedWhisky}
                      title="We haven't tried this whisky yet, what's it like?"
                      flavours={comparisonCoordinates.filter(coordinate => !coordinate.isNotFlavour)}
                  />
                </div>
            )}

          </div>

          {selectedWhisky && selectedWhisky._id && (
              <SimilarWhiskys selectedWhisky={selectedWhisky} comparisonCoordinates={comparisonCoordinates} />
          )}

          {selectedWhisky && <EarlyAccessForm />}

        </main>

      </section>

      {selectedWhisky && <Footer email={contactEmailAddress} />}

      <form data-netlify="true" name="tasteForm">
        <input type="hidden" name="sweet" value="sweet" />
        <input type="hidden" name="smokey" value="smokey" />
        <input type="hidden" name="rich" value="rich" />
        <input type="hidden" name="whisky" value="whisky" />
        <input type="hidden" name="flavours" value="flavours" />
        <input type="hidden" name="form-name" value="tasteForm" />
      </form>

      <form data-netlify="true" name="notFoundForm">
        <input type="hidden" name="sweet" value="sweet" />
        <input type="hidden" name="smokey" value="smokey" />
        <input type="hidden" name="rich" value="rich" />
        <input type="hidden" name="whisky" value="whisky" />
        <input type="hidden" name="flavours" value="flavours" />
        <input type="hidden" name="form-name" value="notFoundForm" />
      </form>
    </>
  );
}
