import React from 'react';

export default ({ whisky }) => (
    <div className="card">
        <div className="card-image">
            <figure className="image is-4by3">
                <img src={`/static/whiskys/${whisky.imageNumber.toString().padStart(4, '0')}_WL.jpg`} alt="" />
            </figure>
        </div>
        <div className="card-content">
            <div className="media">
                <div className="media-content has-text-centered">
                    <p className="title is-5">{ whisky.name }</p>
                    <p className="subtitle is-6">{ whisky.region }</p>
                </div>
            </div>
        </div>
    </div>
)
