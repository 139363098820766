import React, {useState} from 'react';
import './earlyAccessForm.scss';
import { IconContext } from "react-icons";
import { FaPaperPlane } from 'react-icons/fa';

export default () => {
    const [formHasSent, setFormHasSent] = useState(false);
    const [sendingForm, setSendingForm] = useState(false);
    const [email, setEmail] = useState('');

    const onSubmit = (event) => {
        event.preventDefault();

        if (sendingForm || email.length < 6) {
            return;
        }

        let formData = {
            'form-name': 'earlyAccessForm',
            email
        };

        setSendingForm(true);

        fetch('/api/new-beta-subscriber', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        })
            .then(() => {
                setSendingForm(false);
                setFormHasSent(true);
            })
            .catch(error => alert(error));
    };

    return (
        <div className="columns is-centered earlyAccessForm">

            <div className={`column is-8 has-text-centered ${formHasSent ? 'formHasSent' : ''}`}>
                <h2 className="title is-2">Beta coming soon. Want early access?</h2>

                <form onSubmit={onSubmit} data-netlify="true" name="earlyAccessForm">

                    <div className="field has-addons">
                        <div className="control">
                            <input className="input is-large" name="email" type="email" value={email} onChange={e => setEmail(e.target.value)}/>
                        </div>
                        <div className="control">
                            <button
                                className={`button is-large is-outlined ${sendingForm ? 'is-loading' : ''}`}
                                type="submit"
                            >
                                <IconContext.Provider value={{ color: "#fff" }}><FaPaperPlane /></IconContext.Provider>
                            </button>
                        </div>
                    </div>

                    <input type="hidden" name="form-name" value="earlyAccessForm" />
                </form>
                <h3 className="title is-3 submissionThankYou">Great! We will be in touch soon.</h3>
            </div>

        </div>
    );
};
