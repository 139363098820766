import React from 'react';
import SecureEmail from '../../components/secureEmail';
import './footer.scss';

export default ({ email }) => {
    return (
        <footer className="footer">
            <div className="content has-text-centered">
                <p>
                    <SecureEmail email={email} />
                </p>
            </div>
        </footer>
    );
}
