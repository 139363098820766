import React, {useEffect, useState} from 'react';
import WhiskyCard from '../../components/whisky';

export default ({ selectedWhisky, comparisonCoordinates }) => {

    const [status, setStatus] = useState('loading');
    const [previousSelectedWhisky, setPreviousSelectedWhisky] = useState(null);
    const [similarWhiskys, setSimilarWhiskys] = useState(null);

    useEffect(() => {
        let canceled = false;

        if (selectedWhisky !== previousSelectedWhisky) {
            setPreviousSelectedWhisky(selectedWhisky);
            setStatus('loading');
        }

        if (status !== 'loading') {
            return;
        }

        fetch('/api/get-similars', {
          method: 'POST',
          body: JSON.stringify({ id: selectedWhisky.id, comparisonCoordinates }),
          headers: {
            'Content-Type': 'application/json'
          },
        }).then(async result => {
          if (canceled) {
            return;
          }

          if (result.status !== 200) {
            console.error('Error during loading similar whiskies :(');
            console.error(result);
            return;
          }

          const data = await result.json();
          const { similarWhiskys } = data;
          setSimilarWhiskys(similarWhiskys);
          setStatus('loaded');

          return () => {
            canceled = true;
          };
        });

    }, [status, selectedWhisky, previousSelectedWhisky]);

    return (
        <>
            <div className="column is-full has-text-centered">
                <h4 className="title is-4">Similar whiskies:</h4>
            </div>

            <div className="columns is-centered">
                {similarWhiskys && status !== 'loading'
                    ? similarWhiskys.map(whisky => (
                        <div className="column" key={whisky.id}>
                            <WhiskyCard whisky={whisky} />
                        </div>
                    ))
                    : (
                        <div className="column is-8 has-text-centered">
                            <p>Loading similar whiskys...</p>
                            <progress className="progress is-large is-warning" max="100">60%</progress>
                        </div>
                    )}
            </div>
        </>
    );
}
